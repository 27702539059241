/* montserrat-latin-100-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(./files/montserrat-latin-100-normal.woff2) format('woff2'), url(./files/montserrat-latin-100-normal.woff) format('woff');
}

/* montserrat-latin-200-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url(./files/montserrat-latin-200-normal.woff2) format('woff2'), url(./files/montserrat-latin-200-normal.woff) format('woff');
}

/* montserrat-latin-300-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(./files/montserrat-latin-300-normal.woff2) format('woff2'), url(./files/montserrat-latin-300-normal.woff) format('woff');
}

/* montserrat-latin-400-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/montserrat-latin-400-normal.woff2) format('woff2'), url(./files/montserrat-latin-400-normal.woff) format('woff');
}

/* montserrat-latin-500-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(./files/montserrat-latin-500-normal.woff2) format('woff2'), url(./files/montserrat-latin-500-normal.woff) format('woff');
}

/* montserrat-latin-600-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(./files/montserrat-latin-600-normal.woff2) format('woff2'), url(./files/montserrat-latin-600-normal.woff) format('woff');
}

/* montserrat-latin-700-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./files/montserrat-latin-700-normal.woff2) format('woff2'), url(./files/montserrat-latin-700-normal.woff) format('woff');
}

/* montserrat-latin-800-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(./files/montserrat-latin-800-normal.woff2) format('woff2'), url(./files/montserrat-latin-800-normal.woff) format('woff');
}

/* montserrat-latin-900-normal */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(./files/montserrat-latin-900-normal.woff2) format('woff2'), url(./files/montserrat-latin-900-normal.woff) format('woff');
}